<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">

            <a-col :md="8" :sm="24">
              <a-form-item label="机场名称" prop="courtName">
                <a-input v-model="queryParam.name" placeholder="请输入机场名称" allow-clear/>
              </a-form-item>
            </a-col>



            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()"  >
          <a-icon type="plus" />新增
        </a-button>

        <a-button type="danger" :disabled="multiple" @click="handleDelete"  >
          <a-icon type="delete" />删除
        </a-button>

<!--        <a-upload-->
<!--          name="file"-->
<!--          :showUploadList="false"-->
<!--          :multiple="false"-->
<!--          :headers="tokenHeader"-->
<!--          :action="importExcelUrl"-->
<!--          @change="handleImportExcel">-->
<!--          <a-button type="primary" icon="import">导入</a-button>-->
<!--        </a-upload>-->
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['court:court:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="isEnable" slot-scope="text, record">
          <a-switch checkedChildren="是" unCheckedChildren="否" v-model="record.isEnable == 1" @change="changeIsOpen($event,record)"/>
        </span>
        <span slot="operation" slot-scope="text, record">
          <!--          <a-divider type="vertical" v-hasPermi="['court:court:edit']" />-->
          <a @click="$refs.createForm.handleUpdate(record, undefined)"  >
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical"  />
          <a @click="handleDelete(record)"  >
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
         show-size-changer
        show-quick-jumper
        :pageSizeOptions="['10', '50', '100', '1000', '5000']"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageAirport, updateAirport, delAirport, changeIsOpen } from '@/api/airport/airport'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import Template from '@/views/sms/template'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { OSSfileUpload } from '@/utils/request'

export default {
  name: 'Court',
  components: {
    Template,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: null,

        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          align: 'center',
          customRender(text, record, index) {
            return index + 1
          }
        },
        {
          title: '机场名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '位置名称',
          dataIndex: 'position',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '经度',
          dataIndex: 'longitude',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '纬度',
          dataIndex: 'latitude',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '围栏半径(km)',
          dataIndex: 'distance',
          align: 'center'
        },
        {
          title: '是否启用',
          align: 'center',
          dataIndex: 'isEnable',
          scopedSlots: { customRender: 'isEnable' }
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          align: 'center',
          ellipsis: true,
          width: 150,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '10%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
    tokenHeader: {}
    }
  },
  filters: {
  },
  created () {
    const token = storage.get(ACCESS_TOKEN)
    this.tokenHeader['Authorization'] = 'Bearer ' + token
    this.getList()
  },
  computed: {
    // importExcelUrl() {
    //   return OSSfileUpload + '/admin-golf-api/court/court-court/importWithOptions'
    // }
  },
  watch: {
  },
  methods: {
    changeIsOpen (e,record) {
      record.isEnable = e ? 1 : 0
      changeIsOpen(record).then(response => {
        this.$message.success(
          '操作成功'
        )
        return  record.isEnable = e ? 1 : 0
      })
    },
    handleImportExcel(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        if (info.file.response.success) {
          this.$message.success(`${info.file.name} 文件上传成功`)
          // this.loadData();
        } else {
          this.$message.error(`${info.file.name} ${info.file.response.message}.`)
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`文件上传失败: ${info.file.msg}`)
      }
      this.getList()
    },
    /** 查询球场列表 */
    getList () {
      this.loading = true
     pageAirport(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {

        name: undefined,

        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delCourt(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          // 取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download(exportCourt(), {
            ...that.queryParam
          }, `球场_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
